import createLucideIcon from '../createLucideIcon';

const UserCircle = createLucideIcon('UserCircle', [
  ['circle', { cx: '12', cy: '12', r: '10', key: '1mglay' }],
  ['circle', { cx: '12', cy: '10', r: '3', key: 'ilqhr7' }],
  [
    'path',
    { d: 'M7 20.662V19a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v1.662', key: '154egf' },
  ],
]);

export default UserCircle;
